<template>
  <div :class="{ dark: type === 'Dark' }" class="head-container">
    <div class="head-top">
      <div class="icon-box">
        <i class="icon" />
        <span v-if="channelName" class="channel">{{ channelName }}</span>
      </div>
      <skeleton :loading="loading" :row="1" w="3.48rem" h=".6rem">
        <span v-if="createTime" class="time">{{ createTime | formatDate('yyyy.MM.dd HH:mm') }}</span>
      </skeleton>
    </div>
    <div class="head-line">
      <div class="line-child" />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    channelName: {
      type: String,
      default: null
    },
    createTime: {
      type: [Number, String],
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  filters: {
    /**
     * 格式化时间
     * @method formatDate
     * @param {String} val 日期对象/日期字符串/时间戳秒数
     * @param {formate} 格式
     * @return {String} 格式化后的时间
     */
    formatDate(val, format) {
      let fmt = format || 'yyyy-MM-dd HH:mm'
      let d = val

      if (!val) {
        return val
      }

      if (typeof val === 'string') {
        d = new Date(val.replace(/-/g, '/'))
      } else if (typeof val === 'number') {
        d = new Date(val)
      }

      const o = {
        'M+': d.getMonth() + 1, // 月份
        'd+': d.getDate(), // 日
        'H+': d.getHours(), // 小时
        'm+': d.getMinutes(), // 分
        's+': d.getSeconds(), // 秒
        'q+': Math.floor((d.getMonth() + 3) / 3), // 季度
        S: d.getMilliseconds(), // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, `${d.getFullYear()}`.substr(4 - RegExp.$1.length))
      }

      Object.keys(o).forEach(k => {
        if (new RegExp(`(${k})`).test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length))
        }
      })

      return fmt
    },
  }
}
</script>

<style lang="scss" scoped>
  .head-container {
    width: 100%;
    padding-top: .8rem;
    box-sizing: border-box;
    &.dark {
      .time {
        color: #999999 !important;
      }
      .head-line {
        background: #5E5E5E !important;
      }
    }
    .head-top {
      display: flex;
      align-items: center;
      padding-bottom: .6rem;
      .icon-box {
        display: flex;
        align-items: center;
        margin-right: .48rem;
        .icon {
          width: 1.04rem;
          height: 1.04rem;
          background: url('~@/assets/images/detail_top_logo.png') no-repeat;
          background-size: 100%;
        }
        .channel {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2.08rem;
          height: 1.04rem;
          background: #FFA600;
          color: #131313;
          font-size: .48rem;
          font-weight: bold;
        }
      }
      .time {
        font-size: .8rem;
        letter-spacing: 0.02rem;
        color: #333333;
        font-family: "Nr-regular";
      }
    }
    .head-line {
      width: 100%;
      height: .08rem;
      background: #131313;
    }
  }
</style>